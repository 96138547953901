@import "../../styles/variables";

.button {
	&.full-width {
		width: 100%;
	}

	&:global(.ant-btn) {
		// Added this class to increase specificity
		border: none;
		border-radius: 8px;
		font-size: 14px;
		font-weight: 500;
		line-height: 20px;
		padding: 10px 17px;
		display: flex;
		gap: 5px;
		align-items: center;
		justify-content: center;
		min-height: 40px;
		min-width: 40px;
		color: $color-btn-text;

		&:focus-visible {
			outline: none;
		}

		:global {
			.ant-btn-icon {
				margin-right: 0px !important;
			}
		}

		&.icon-right {
			svg {
				order: 1;
			}
			:global {
				.ant-btn-icon {
					order: 1;
				}
			}
		}

		svg {
			height: 18px;
			width: 18px;
			vertical-align: middle;
		}

		&:global(.ant-btn-sm) {
			/*For compact button type prop of the button is passed as small.
            Antd adds this class to small type button
            Min height and width are set for icon only button. 
            If there is text then the width will automatically expand
            */
			min-height: 36px;
			min-width: 36px;
			border-radius: 8px;
		}

		&.primary {
			// color: $color-btn-text;
			background-color: $color-btn-background-primary;
			border: 1px solid #f9c939;
			box-shadow: 0px 1px 2px 0px #1018280d;

			&:hover,
			&:focus-visible {
				color: $color-btn-text;
				background: $color-btn-primary-hover;
				border-color: #daaa10;
				opacity: 0.9;
			}

			&:focus {
				color: $color-btn-text;
			}

			&[disabled],
			&[disabled]:hover {
				background-color: #fff9cf;
				color: #bfbfbf;
				border-color: #fff9cf;

				svg {
					stroke: $color-border-btn-secondary;
				}
			}
		}

		&.default {
			background-color: #ffffff;
			border: 1px solid $color-border-btn-secondary;

			// box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
			&:hover,
			&:focus-visible {
				color: $color-btn-text;
				background-color: $color-btn-secondary-background;
				border: 1px solid $color-border-btn-secondary;
			}

			&:focus {
				color: $color-btn-text;
			}

			&[disabled],
			&[disabled]:hover {
				background-color: #ffffff;
				color: $color-border-btn-secondary;
				border: 1px solid $color-btn-secondary-disabled-border;

				svg {
					stroke: $color-border-btn-secondary;
				}
			}
		}

		&.link,
		&.text {
			padding: 0px;
			min-height: auto;
			background: none;
			box-shadow: none;
		}

		&.link {
			color: $color-btn-link-primary;
			min-height: unset;
			height: unset;
			box-shadow: none;

			span {
				&:not(:global(.anticon)) {
					text-decoration: underline;
					text-underline-offset: 4px;
				}
			}

			svg {
				stroke: $color-btn-link-primary;
			}

			&[disabled],
			&[disabled]:hover,
			&:hover {
				color: $color-btn-link-primary;
			}
		}

		&.text {
			//This is for secondary link
			color: $color-btn-link-secondary;

			svg {
				stroke: $color-btn-link-secondary;
			}

			&[disabled],
			&[disabled]:hover {
				color: $color-btn-link-secondary;
			}
		}

		&.icon-only {
			padding: 0px;
			aspect-ratio: 1/1;
			width: unset;
			min-width: unset;
		}

		&.danger {
			color: white;
			background: $color-btn-danger;
			border-color: $color-btn-danger;

			&:hover,
			&:focus-visible {
				color: white;
				background: $color-btn-danger !important;
				border-color: $color-btn-danger;
				opacity: 0.8;
			}

			&:focus {
				color: white;
			}

			&[disabled] {
				opacity: 0.6;
			}
		}
	}
}
