@import "../../styles/variables.scss";

.profile-image-wrapper {
	position: relative;
	width: 120px;
	min-width: 120px;
	height: 120px;
	min-height: 120px;

	&.small,
	&.small .profile-image {
		width: 36px;
		min-width: 36px;
		height: 36px;
		min-height: 36px;
	}

	&.medium,
	&.medium .profile-image {
		width: 61px;
		min-width: 61px;
		height: 61px;
		min-height: 61px;
	}

	.profile-image {
		width: 100%;
		height: 100%;
		min-width: 120px;
		min-height: 120px;
		object-fit: cover;
		border: 3px solid white;
		box-shadow:
			0 4px 6px rgba(0, 0, 0, 0.2),
			0 1px 3px rgba(0, 0, 0, 0.08);
		border-radius: 50%;
		background-color: $color-btn-primary;
	}

	.edit-icon {
		position: absolute;
		top: 5px;
		right: 5px;
		background-color: white;
		border-radius: 50% !important;
		box-shadow: 0px 1px 2px 0px #1018280d;
		padding: 5px;
		min-height: 30px !important;
		min-width: 30px !important;

		>svg {
			width: 16px;
			height: 16px;
		}

		&.small {
			min-width: 14px;
			min-height: 14px;
			max-width: 14px;
			max-height: 14px;
			padding: 2px;
			top: 0px;
			right: 0px;
		}

		&.medium {
			min-width: 20px;
			min-height: 20px;
			max-width: 20px;
			max-height: 20px;
			padding: 4px;
			top: 0px;
			right: 0px;
		}
	}

	.loading-overlay {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(255, 255, 255, 0.7);
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 50%;
	}
}